import React, { useEffect, useState } from "react";
import business from "../../services/business.js";
import InputForm from "../Form/InputForm.js";
import Modal from "../Modal";
import Loading from "../Loading.js";
import tools from "../../lib/tools.js";
import { useAlert } from "react-alert";
import Cep from "cep-promise";
import config from "../../config/config.js";

export default function ModalOperadora({ hideModal, show, dto = {} }) {
  const [validacao, setValidacao] = useState({});
  const [form, setForm] = useState(dto);
  const [loaded, setLoaded] = useState(false);
  const [etapas, setEtapas] = useState([
    { id: "dados_operadora", nome: "Dados gerais" },
  ]);
  const [options, setOptions] = useState({});
  const [permitirCadastro, setPermitirCadastro] = useState(false);
  const [salvandoDados, setSalvandoDados] = useState(false);
  const [stage, setStage] = useState("dados_operadora");
  const alert = useAlert();

  const getCombos = async () => {
    if (!options?.estados) {
      let estados = business.getEstados();
      setOptions({
        ...{ estados },
        ...options,
      });
    }
  };

  const getOperadora = async () => {
    if (dto?.id && !loaded) {
      let operadora = await business.getOperadoraById(dto.id);
      setForm(operadora);
      setLoaded(true);
    }
  };

  const checkIsValidate = () => {
    let validated = true;
    let validacao = {};

    const obrigatorios = [];

    document
      .querySelectorAll(".dados-operadora [name][required]")
      .forEach((e) => {
        if (e.name) obrigatorios.push(e.name);
      });

    setEtapas([
      { id: "dados_operadora", nome: "Dados gerais" },
      { id: "endereco", nome: "Endereço" },
      { id: "valores", nome: "Valores" },
    ]);

    obrigatorios.forEach((campo) => {
      if (
        typeof form[campo] === "undefined" ||
        tools.isEmptyNullOrUndefined(form[campo])
      ) {
        validacao[campo] = "Campo Obrigatório";
        validated = false;
      } else if (["cnpj"].includes(campo)) {
        const { valido } = tools.validarCNPJ(form[campo]);

        if (!valido) {
          validacao[campo] = "Digite um CNPJ válido.";
          validated = false;
        }
      } else if (["nome"].includes(campo)) {
        const valido = form[campo].length > 2;

        if (!valido) {
          validacao[campo] = "Digite um nome válido";
          validated = false;
        }
      } else if (["email"].includes(campo)) {
        const valido = tools.validarEmail(form[campo]);

        if (!valido) {
          validacao[campo] = "Digite um e-mail válido";
          validated = false;
        }
      } else if (["telefone", "contato_telefone"].includes(campo)) {
        const valido = tools.validarTelefone(form[campo]);

        if (!valido) {
          validacao[campo] = "Digite um telefone válido";
          validated = false;
        }
      }
    });

    if (form["telefone2"]?.trim()) {
      const valido = tools.validarTelefone0800(form["telefone2"]);

      if (!valido) {
        validacao["telefone2"] = "Digite um telefone válido";
        validated = false;
      }
    }

    setValidacao(validacao);
    setPermitirCadastro(validated);
  };

  const setModel = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const getCep = async (e) => {
    if (!form.cep) return;
    Cep(form.cep).then((cep) => {
      setForm({
        ...form,
        ...{
          cidade: cep.city,
          logradouro: cep.street,
          bairro: cep.neighborhood,
          uf: cep.state,
          cep: cep.cep,
        },
      });
      let input = document.getElementsByName("numero");
      if (input.length) input[0].focus();
    });
  };

  const salvar = async (e) => {
    checkIsValidate();

    if (!permitirCadastro) {
      alert.error("Preencha todos os campos obrigatórios corretamente.");
      return;
    }

    let formData = { ...form, status: "1" };

    setSalvandoDados(true);

    const { errors } = await business.addOperadora(formData, form?.id || false);

    if (errors && errors.length) {
      errors.forEach((error) => alert.error(error));
      setSalvandoDados(false);
    } else {
      alert.success(
        form?.id ? "As informações foram salvas" : "A operadora foi criada"
      );
      hideModal(e, true);
    }

    setSalvandoDados(false);
  };

  useEffect(() => {
    checkIsValidate();
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    getCombos();
    getOperadora();
    // eslint-disable-next-line
  }, [dto]);

  return (
    <Modal
      show={show}
      handleClose={hideModal}
      style={{ maxWidth: "1200px", maxHeight: "95%" }}
      modalClass="modal-cad-usuario"
    >
      <form
        autoComplete="off"
        onSubmit={() => {
          return false;
        }}
      >
        <div className="flex">
          <div className="options flex fd-fc jc-sb">
            <div className="ml-10">
              <h1 className="title mb-45">
                {form.id ? "Editar operadora" : "Nova operadora"}
              </h1>
              <ul
                className={`f600-18 p-0 flex fd-fc stage gap-15 ${options ? "" : "disabled"
                  }`}
              >
                {etapas.map((etapa) => (
                  <li
                    key={etapa.id}
                    className={etapa.id === stage ? "active" : ""}
                    onClick={() => {
                      if (options) setStage(etapa.id);
                    }}
                  >
                    {etapa.nome}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-primary w-100 fb-16"
                disabled={!permitirCadastro || salvandoDados}
                onClick={salvar}
              >
                {salvandoDados ? (
                  <Loading />
                ) : form.id ? (
                  "Salvar"
                ) : (
                  "Criar Operadora"
                )}
              </button>
            </div>
          </div>
          <div className="form w-100">
            <div className="dados-operadora">
              <div
                className={`etapa ${stage === "dados_operadora" ? "ativa" : ""
                  }`}
              >
                <h1 className="subtitle mb-45">Dados gerais</h1>
                <div className="form-container grid col-12">
                  <InputForm
                    label="Nome"
                    className="span-12"
                    value={form.nome}
                    name="nome"
                    model={setModel}
                    error={validacao.nome}
                    required
                  />
                  <InputForm
                    label="CNPJ"
                    className="span-6"
                    mask="##.###.###/####-##"
                    value={form.cnpj}
                    name="cnpj"
                    model={setModel}
                    error={validacao.cnpj}
                    validate="cnpj"
                    required
                  />
                  <InputForm
                    label="E-mail"
                    className="span-6"
                    type="email"
                    value={form.email}
                    error={validacao.email}
                    name="email"
                    model={setModel}
                    required
                  />
                  <InputForm
                    label="Telefone"
                    className="span-6"
                    mask={"(##) #####-####"}
                    error={validacao.telefone}
                    value={form.telefone}
                    name="telefone"
                    model={setModel}
                    required
                  />
                  <InputForm
                    label="Telefone atendimento (0800)"
                    className="span-6"
                    mask={"#### ### ####"}
                    error={validacao.telefone2}
                    value={form.telefone2}
                    name="telefone2"
                    model={setModel}
                  />
                  <InputForm
                    label="Contato Nome"
                    className="span-6"
                    value={form.contato_nome}
                    error={validacao.contato_nome}
                    name="contato_nome"
                    model={setModel}
                  />
                  <InputForm
                    label="Contato Telefone"
                    className="span-6"
                    mask={"(##) #####-####"}
                    value={form.contato_telefone}
                    error={validacao.contato_telefone}
                    name="contato_telefone"
                    model={setModel}
                  />
                  <InputForm
                    label="Observações"
                    type="textarea"
                    height={100}
                    maxLength={1024}
                    className="span-12"
                    value={form.descricao}
                    name="descricao"
                    model={setModel}
                  />
                </div>
              </div>
              <div className={`etapa ${stage === "endereco" ? "ativa" : ""}`}>
                <h1 className="subtitle mb-45">Endereço</h1>
                <div className="form-container grid col-12">
                  <InputForm
                    label="CEP"
                    mask={"#####-###"}
                    className="span-4"
                    value={form.cep}
                    name="cep"
                    onBlur={getCep}
                    error={validacao.cep}
                    model={setModel}
                    required
                  >
                    <i
                      className="icon icon-bolt blue i-15"
                      style={{
                        position: "absolute",
                        right: "18px",
                        top: "17px",
                      }}
                    />
                  </InputForm>
                  <InputForm
                    label="Logradouro"
                    className="span-8"
                    value={form.logradouro}
                    error={validacao.logradouro}
                    name="logradouro"
                    model={setModel}
                    required
                  />
                  <InputForm
                    label="Número"
                    className="span-4"
                    value={form.numero}
                    name="numero"
                    error={validacao.numero}
                    model={setModel}
                    required
                  />
                  <InputForm
                    label="Complemento"
                    className="span-8"
                    value={form.complemento}
                    error={validacao.complemento}
                    name="complemento"
                    model={setModel}
                  />
                  <InputForm
                    label="Bairro"
                    className="span-4"
                    value={form.bairro}
                    error={validacao.bairro}
                    name="bairro"
                    model={setModel}
                    required
                  />
                  <InputForm
                    label="Cidade"
                    className="span-6"
                    value={form.cidade}
                    name="cidade"
                    error={validacao.cidade}
                    model={setModel}
                    required
                  />
                  <InputForm
                    required
                    label="Estado"
                    className="span-2"
                    type="select"
                    placeholder={"UF"}
                    error={validacao.uf}
                    value={form.uf}
                    name="uf"
                    {...(form?.uf ? { required: true } : {})}
                    model={setModel}
                    options={(options?.estados || []).map((item) => {
                      return { id: item.id, nome: item.id };
                    })}
                  />
                </div>
              </div>
              <div className={`etapa ${stage === "valores" ? "ativa" : ""}`}>
                <h1 className="subtitle mb-45">Valores</h1>
                <div className="grid md-gap">
                  <div className="col-12 flex md-gap">
                    <InputForm
                      label="Consenso Pré"
                      type="decimal"
                      className="span-6"
                      value={form.valor_cp}
                      name="valor_cp"
                      model={setModel}
                      error={validacao.valor_cp}
                      validate="valor_cp"
                      disabled={!config.isAdmin() && !config.isAdmin()}
                    />
                    <InputForm
                      label="Segunda Opinião"
                      type="decimal"
                      error={validacao.valor_so}
                      className="span-6"
                      value={form.valor_so}
                      name="valor_so"
                      model={setModel}
                      validate="valor_so"
                      disabled={!config.isAdmin() && !config.isAdmin()}
                    />
                  </div>
                  <div className="col-12 flex md-gap">
                    <InputForm
                      label="JMO (Documental)"
                      error={validacao.valor_jmo_doc}
                      type="decimal"
                      className="span-6"
                      value={form.valor_jmo_doc}
                      name="valor_jmo_doc"
                      model={setModel}
                      validate="valor_jmo_doc"
                      disabled={!config.isAdmin() && !config.isAdmin()}
                    />
                    <InputForm
                      label="JMO (Vídeo)"
                      type="decimal"
                      error={validacao.valor_jmo_video}
                      className="span-6"
                      value={form.valor_jmo_video}
                      name="valor_jmo_video"
                      model={setModel}
                      validate="valor_jmo_video"
                      disabled={!config.isAdmin() && !config.isAdmin()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
