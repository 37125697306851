import moment from "moment/moment.js";
import 'moment/locale/pt-br';
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ComAberturaJuntaBNF from "../components/Emails/ComAberturaJuntaBNF.js";
import ComAberturaJuntaPA from "../components/Emails/ComAberturaJuntaPA.js";
import ComDecisaoJuntaBNF from "../components/Emails/ComDecisaoJuntaBNF.js";
import ComDecisaoJuntaPA from "../components/Emails/ComDecisaoJuntaPA.js";
import ComEscolhaAbstencaoBNF from "../components/Emails/ComEscolhaAbstencaoBNF.js";
import ComEscolhaAbstencaoPA from "../components/Emails/ComEscolhaAbstencaoPA.js";
import ComEscolhaBNF from "../components/Emails/ComEscolhaBNF.js";
import ComEscolhaPA from "../components/Emails/ComEscolhaPA.js";
import ConviteDesempatador from "../components/Emails/ConviteDesempatador.js";
import ParOpiniaoEspecializada from "../components/Emails/ParOpiniaoEspecializada.js";
import ParTecnicoConclusivo from "../components/Emails/ParTecnicoConclusivo.js";
import RetJunta from "../components/Emails/RetJunta.js";
import SolExamesComplementaresBNF from "../components/Emails/SolExamesComplementaresBNF.js";
import SolExamesComplementaresOPS from "../components/Emails/SolExamesComplementaresOPS.js";
import ValConsensoParcial from "../components/Emails/ValConsensoParcial.js";
import config from "../config/config.js";
import business from "../services/business.js";
import { useParams } from "react-router-dom";
import tools from "../lib/tools.js";
import { destroyCookie } from "nookies";
import Preloader from '../components/Preloader';
import ModalAlert from "../components/Modais/ModalAlert.js";
import { isAuthenticated } from "../services/auth.js";
import ModalAutenticar from "../components/Modais/ModalAutenticar.js";
import { useAlert } from "react-alert";
import ComRecusaConsensoPA from "../components/Emails/ComRecusaConsensoPA.js";
import ComRecusaConsensoPAConsensoPre from "../components/Emails/ComRecusaConsensoPAConsensoPre.js";
import ComConsensoTecnicoOPS from "../components/Emails/ComConsensoTecnicoOPS.js";
import ComConsensoTecnicoBNF from "../components/Emails/ComConsensoTecnicoBNF.js";
import ComConsensoTecnicoPA from "../components/Emails/ComConsensoTecnicoPA.js";
import ComRecusaConsensoParcialPAConsensoPre from "../components/Emails/ComRecusaConsensoParcialPAConsensoPre.js";

export default function EmailPage() {

    let { id, hash } = useParams();

    const [processo, setProcesso] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalData, setModalData] = useState({});
    const [logged, setLogged] = useState(false);
    const [render, setRender] = useState(false);
    const [submittedLogin, setSubmittedLogin] = useState(false);
    const [data, setData] = useState({});
    const alert = useAlert();

    useEffect(() => {

        async function getEmailInfo() {
            setLoading(true);

            let access_token = getAcessToken();
            let response = await business.getProcessoByProtocolo(id, hash, access_token);

            if (response?.metodo_autenticacao === 'login' && (!isAuthenticated() || !response?.id)) {
                clear(isAuthenticated() ? 1 : false);
            } else if (['cpf', 'crm', 'cnpj'].includes(response?.metodo_autenticacao) && response?.id && isPermitted()) {
                setLogged(true);
            } else if (['login'].includes(response?.metodo_autenticacao) && response?.id && isAuthenticated()) {
                setLogged(true);
            } else {
                reset();
            }

            setProcesso(response);
            setLoading(false);
        }

        getEmailInfo();

        // eslint-disable-next-line
    }, [id, hash, render]);

    const getAcessToken = function () {
        return localStorage.getItem('access_token') || '';
    }

    const isPermitted = () => {
        return (getAcessToken() ? true : false);
    }

    const clear = (error = false) => {
        setLogged(false);
        destroyCookie({}, 'token');
        localStorage.clear();
        return tools.openURL(`/login?redirect=${window.location.href}${(error) ? `&error=${error}` : ''}`);
    }

    const cancel = () => {
        setLogged(false);
        localStorage.setItem('access_token', '');
        return tools.openURL('/login');
    }

    const reset = () => {
        setLogged(false);
        localStorage.setItem('access_token', '');
    }

    const submit = async (e, form, setValidacao) => {
        e.preventDefault();

        const obrigatorios = [];

        document.querySelectorAll('.form [name][required]').forEach((e) => {
            if (e.name && e.style.display !== 'none') obrigatorios.push(e.name);
        });

        let validacao = {};
        let validated = true;

        obrigatorios.forEach(campo => {
            let keys = campo.split('.');
            if (keys.length > 1) {
                if (typeof form[keys[0]][keys[1]] == 'undefined' || tools.isEmptyNullOrUndefined(form[keys[0]][keys[1]])) {
                    validacao[campo] = 'Campo Obrigatório';
                    validated = false;
                } else if (["cpf"].includes(keys[1])) {
                    let validCpf = tools.validarCPF(form[keys[0]][keys[1]]);
                    if (!validCpf.valido) {
                        validacao[campo] = validCpf.texto;
                        validated = false;
                    }
                } else if (["email"].includes(keys[1])) {
                    let validEmail = tools.validarEmail(form[keys[0]][keys[1]]);
                    if (!validEmail) {
                        validacao[campo] = "Digite um e-mail válido";
                        validated = false
                    }
                } else if (["telefone"].includes(keys[1])) {
                    let validarTelefone = tools.validarTelefone(form[keys[0]][keys[1]]);
                    if (!validarTelefone) {
                        validacao[campo] = "Digite um telefone válido";
                        validated = false
                    }
                }
            } else if (typeof form[campo] == 'undefined' || tools.isEmptyNullOrUndefined(form[campo])) {
                validacao[campo] = 'Campo Obrigatório';
                validated = false;
            } else if (["cpf"].includes(campo)) {
                let validCpf = tools.validarCPF(form[campo]);
                if (!validCpf.valido) {
                    validacao[campo] = validCpf.texto;
                    validated = false;
                }
            }
        });

        setValidacao(validacao);

        if (validated) {

            setSubmittedLogin(true);

            const { response, errors } = await business.processoAuth(id, hash, form);

            if (errors && errors.length) {
                (errors || ['Ocorreu um erro ao tentar realizar ação']).forEach(e => alert.error(e));
                reset();
            } else if (response?.access_token) {
                localStorage.setItem('access_token', response?.access_token);
                setLogged(true);
            } else {
                alert.error('Não foi possível se autenticar, contate o administrador do sistema');
                reset();
            }

            setSubmittedLogin(false);
            setRender(tools.uuidv4());
        }
    }
    //verificar ordem de carregamento
    let item = (processo?.processo_disparo) ? processo.processo_disparo : processo;

    useEffect(() => {
        (async () => {
            setData({
                protocolo: item?.id_protocolo,
                nome_usuario: config?.user?.nome,
                operadora_nome: item?.desc_operadora?.toUpperCase(),
                operadora_endereco: [processo?.operadora?.logradouro?.toUpperCase(), processo?.operadora?.numero, processo?.operadora?.bairro?.toUpperCase(), processo?.operadora?.cidade?.toUpperCase()].filter((a) => { return a; }).join(', ') + (processo?.operadora?.uf ? ' - ' + processo?.operadora?.uf : '') || 'Endereço indisponível',
                operadora_telefone: processo?.operadora?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3") || 'Telefone indisponível',
                operadora_imagem: processo?.endereco_operadora?.imagem ? processo?.endereco_operadora?.imagem : processo?.operadora?.imagem,
                data_disparo: moment(config.dataHoraAtual()).format('LL'),
                profissional_nome: item?.profissional?.nome?.toUpperCase(),
                profissional_crmcro: item?.profissional?.crm,
                profissional_uf: item?.profissional?.uf,
                profissional_telefone: item?.profissional?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3"),
                data_atendimento: moment(item?.data_atendimento).format('DD/MM/YYYY'),
                beneficiario_nome: item?.beneficiario?.nome?.toUpperCase(),
                beneficiario_cpf: item?.beneficiario?.cpf,
                beneficiario_sexo: item?.beneficiario?.sexo === 'M' ? 'MASCULINO' : 'FEMININO',
                beneficiario_guia: item?.guia,
                beneficiario_nascimento: item?.beneficiario?.nascimento,
                beneficiario_idade: tools.calcularIdade(item?.beneficiario?.nascimento),
                auditor_nome: item?.auditor?.nome?.toUpperCase(),
                auditor_crmcro: item?.auditor?.crm,
                auditor_uf: item?.auditor?.uf,
                assistente_responsavel_nome: item?.responsaveis?.nome_assistente?.toUpperCase(),
                enfermeira_responsavel_nome: item?.responsaveis?.nome_enfermeira?.toUpperCase(),
                desempatador_responsavel_nome: item?.responsaveis?.nome_desempatador?.toUpperCase(),
                desempatador_responsavel_crmcro: item?.responsaveis?.crm_desempatador || item?.responsaveis?.cro_desempatador,
                desempatador_responsavel_uf: item?.responsaveis?.crm_uf_desempatador || item?.responsaveis?.cro_uf_desempatador,
                desempatador_responsavel_laudo: item?.texto_parecer,
                procedimentos: (item?.procedimentos || []).map((p) => {
                    return {
                        id: p.id_procedimento,
                        codigo: p?.codigo || '-',
                        descricao: p.desc_procedimento,
                        quantidade_solicitada: p.quantidade_solicitada || 0,
                        quantidade_autorizada: p.quantidade_aprovada || 0,
                        quantidade_pos_analise: p.quantidade_pos_analise || 0,
                        id_divergencia: p?.id_processo_motivo_divergencia || '-',
                        divergencia: p?.desc_processo_motivo_divergencia || '-',
                        justificativa: p.observacao || '-',
                        justificativa_pos_analise: p.justificativa_analise || '-'
                    };
                }),
                materiais: (item?.materiais || []).map((m) => {
                    return {
                        id: m.id_material,
                        descricao: m.desc_material,
                        quantidade_solicitada: m.quantidade_solicitada || 0,
                        quantidade_autorizada: m.quantidade_aprovada || 0,
                        quantidade_pos_analise: m.quantidade_pos_analise || 0,
                        id_divergencia: m?.id_processo_motivo_divergencia || '-',
                        divergencia: m?.desc_processo_motivo_divergencia || '-',
                        justificativa: m.observacao || '-',
                        justificativa_pos_analise: m.justificativa_analise || '-'
                    };
                }),
                desempatadores: item?.desempatadores,
                etapa: processo?.id_processo_etapa,
                status: processo?.id_processo_etapa_status,
                id_desempatador: processo?.responsaveis?.id_desempatador || 0,
                notificacao_envio: item?.notificacao_envio || {},
                exames_complementares: [],
                metodo_autenticacao: item?.metodo_autenticacao || false,
                conclusao: item?.processo_conclusao
            });

        })();
        // eslint-disable-next-line
    }, [item, render]);

    return (
        <>
            <ModalAutenticar show={!logged && data?.metodo_autenticacao} cancel={cancel} submit={submit} data={data} submitted={submittedLogin} />
            <ModalAlert title={modalData?.title} text={modalData?.text} show={modalData?.show} onAccept={modalData?.onAccept} onClose={modalData?.onClose} buttonCancel={modalData?.buttonCancel} />
            {(loading) ? <Preloader /> : (logged && <div>
                {processo?.componente === 'ComAberturaJuntaBNF' && <ComAberturaJuntaBNF resp={data} />}
                {processo?.componente === 'ComAberturaJuntaPA' && <ComAberturaJuntaPA id={id} hash={hash} resp={data} setModal={setModalData} />}
                {processo?.componente === 'ComConsensoTecnicoOPS' && <ComConsensoTecnicoOPS resp={data} />}
                {processo?.componente === 'ComConsensoTecnicoBNF' && <ComConsensoTecnicoBNF resp={data} />}
                {processo?.componente === 'ComConsensoTecnicoPA' && <ComConsensoTecnicoPA resp={data} />}
                {processo?.componente === 'ComDecisaoJuntaBNF' && <ComDecisaoJuntaBNF resp={data} />}
                {processo?.componente === 'ComDecisaoJuntaPA' && <ComDecisaoJuntaPA resp={data} />}
                {processo?.componente === 'ComEscolhaAbstencaoBNF' && <ComEscolhaAbstencaoBNF resp={data} />}
                {processo?.componente === 'ComEscolhaAbstencaoPA' && <ComEscolhaAbstencaoPA resp={data} />}
                {processo?.componente === 'ComEscolhaBNF' && <ComEscolhaBNF resp={data} />}
                {processo?.componente === 'ComEscolhaPA' && <ComEscolhaPA resp={data} />}
                {processo?.componente === 'ParTecnicoConclusivo' && <ParTecnicoConclusivo resp={data} />}
                {processo?.componente === 'ParOpiniaoEspecializada' && <ParOpiniaoEspecializada resp={data} />}
                {processo?.componente === 'RetJunta' && <RetJunta resp={data} />} {/* ADICIONAR NAS NOTIFICAÇÕES???, FALTAM CAMPOS */}
                {processo?.componente === 'SolExamesComplementaresBNF' && <SolExamesComplementaresBNF resp={data} />}
                {processo?.componente === 'SolExamesComplementaresOPS' && <SolExamesComplementaresOPS resp={data} />}
                {processo?.componente === 'ValConsensoParcial' && <ValConsensoParcial resp={data} />}
                {processo?.componente === 'ConviteDesempatador' && <ConviteDesempatador id={id} hash={hash} resp={data} setModal={setModalData} />}
                {processo?.componente === 'ComRecusaConsensoParcialPA' && <ComRecusaConsensoPA resp={data} />}
                {processo?.componente === 'ComRecusaConsensoPAConsensoPre' && <ComRecusaConsensoPAConsensoPre resp={data} />}
                {processo?.componente === 'ComRecusaConsensoParcialPAConsensoPre' && <ComRecusaConsensoParcialPAConsensoPre resp={data} />}
            </div>)}
        </>
    );
}