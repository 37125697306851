import React, { useEffect, useState } from "react";
import moment from "moment";
import tools from "../../lib/tools";
import { useParams } from "react-router-dom";
import business from "../../services/business";
import Loading from "../../components/Loading";

export default function DocParecerSegundaOpiniao() {

    let { id_processo } = useParams();
    document.title = 'säästo';
    const [processo, setProcesso] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        async function getProcess() {
            setProcesso(await business.getProcesso(id_processo))
            setLoading(false);
        }
        getProcess();
    }, [id_processo]);

    const data = {
        protocolo: processo?.id_protocolo,
        operadora_nome: processo?.desc_operadora,
        operadora_endereco: processo?.endereco_operadora?.logradouro + ', ' + processo?.endereco_operadora?.numero + ', ' + processo?.endereco_operadora?.bairro + ', ' + processo?.endereco_operadora?.cidade + ' - ' + processo?.endereco_operadora?.uf,
        operadora_telefone: processo?.endereco_operadora?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3") || 'Telefone indisponível',
        operadora_imagem: processo?.endereco_operadora?.imagem,
        data_disparo: moment(processo?.data_validacao).format('LL'),
        profissional_nome: processo?.profissional?.nome,
        profissional_crmcro: processo?.profissional?.crm,
        profissional_uf: processo?.profissional?.uf,
        profissional_telefone: processo?.profissional?.telefone.replace(/(\d{2})(\d{4})(\d{4,5})$/, "($1) $2-$3"),
        data_atendimento: moment(processo?.data_atendimento).format('DD/MM/YYYY'),
        beneficiario_nome: processo?.beneficiario?.nome,
        beneficiario_cpf: processo?.beneficiario?.cpf,
        beneficiario_sexo: processo?.beneficiario?.sexo === 'M' ? 'masculino' : 'feminino',
        beneficiario_guia: processo?.guia,
        beneficiario_nascimento: processo?.beneficiario?.nascimento,
        beneficiario_idade: tools.calcularIdade(processo?.beneficiario?.nascimento),
        auditor_nome: processo?.auditor?.nome,
        auditor_crmcro: processo?.auditor?.crm,
        auditor_uf: processo?.auditor?.uf,
        assistente_responsavel_nome: processo?.responsaveis?.nome_assistente,
        enfermeira_responsavel_nome: processo?.responsaveis?.nome_enfermeira,
        desempatador_responsavel_nome: processo?.responsaveis?.nome_desempatador,
        desempatador_responsavel_crmcro: processo?.responsaveis?.crm_desempatador || processo?.responsaveis?.cro_desempatador,
        desempatador_responsavel_uf: processo?.responsaveis?.crm_uf_desempatador || processo?.responsaveis?.cro_uf_desempatador,
        desempatador_responsavel_laudo: processo?.texto_parecer,
        desempatador_responsavel_historico_beneficiario: processo?.texto_historico_beneficiario,
        desempatador_responsavel_descricao: processo?.responsaveis?.descricao_desempatador,
        data_inicio: moment(processo?.inicio_processo).format('DD/MM/YYYY'),
        data_parecer: moment(processo?.data_validacao).format('LL'),
        data_parecer_min: moment(processo?.data_validacao).format('DD/MM/YYYY'),
        observacao: processo?.observacao,
        procedimentos: (processo?.procedimentos || []).map((p) => {
            return {
                id: p.id_procedimento,
                codigo: p?.codigo || '-',
                descricao: p.desc_procedimento,
                quantidade_solicitada: p.quantidade_solicitada || 0,
                quantidade_autorizada: p.quantidade_aprovada || 0,
                quantidade_pos_analise: p.quantidade_pos_analise || 0,
                divergencia: p?.desc_processo_motivo_divergencia || '-',
                justificativa: p.observacao || '-',
                justificativa_pos_analise: p.justificativa_analise || '-'
            };
        }),
        materiais: (processo?.materiais || []).map((m) => {
            return {
                id: m.id_material,
                descricao: m.desc_material,
                quantidade_solicitada: m.quantidade_solicitada || 0,
                quantidade_autorizada: m.quantidade_aprovada || 0,
                quantidade_pos_analise: m.quantidade_pos_analise || 0,
                divergencia: m?.desc_processo_motivo_divergencia || '-',
                justificativa: m.observacao || '-',
                justificativa_pos_analise: m.justificativa_analise || '-'
            };
        }),
        desempatadores: processo?.desempatadores,
        id_desempatador: processo?.responsaveis?.id_desempatador || 0,
        notificacao_envio: processo?.notificacao_envio || {},
        exames_complementares: [],
    }

    return (
        <div> {loading ? (<div className="p-30 flex"><Loading /> <span className="pl-10">Aguarde, carregando parecer...</span></div>) :
            <div className="print-email p-40">
                <div className="float-right" id="no-print">
                    <button className="btn btn-primary f700-16 jc-sb" onClick={() => (window.print())}>
                        Imprimir <i className="icon icon-print rotate-180 i-20 ml-10" />
                    </button>
                </div>
                <br />
                <div className="flex">
                    <div className="w-100 mb-20">
                        <p className="mb-0"><b>Operadora:</b> {data.operadora_nome}</p>
                        <p className="mb-0"><b>Endereço:</b> {data.operadora_endereco}</p>
                        <p className="mb-0"><b>Telefone:</b> {data.operadora_telefone}</p>
                    </div>
                    <img className="image-ops" src={data?.operadora_imagem} alt='Operadora'></img>
                </div>
                <div className="w-100">
                    <div className="text-right pb-5"><b>Data do encaminhamento:</b> {data?.data_inicio} </div>
                    <div className="text-right pb-5"><b>Data da emissão:</b> {data?.data_parecer_min}</div>
                </div>
                <div className="w-100" style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
                    <p className="text-left mt-10 fb-18">DADOS DO PROCESSO:</p>
                    <div className="pb-5"><b>Nome Beneficiário(a): </b>{data?.beneficiario_nome}</div>
                    <div className="pb-5"><b>ID: </b>{data?.protocolo}<b> Guia: </b>{data?.beneficiario_guia}</div>
                    <br />
                    <p className="text-left mb-10 fb-18">SOLICITAÇÃO DA OPERADORA:</p>
                    <p>{data.observacao ? data.observacao : '(campo onde a operadora irá descrever/questionar as dúvidas referente a solicitação do profissional assistente)'}</p>
                    <br />
                </div>
                <div className="w-100">
                    <p className="text-center mt-30 mb-30 fb-18">PARECER SEGUNDA OPINIÃO ESPECIALIZADA</p>
                </div>
                {/*  <div className="w-100">
                    <div className="text-right mb-10">{data.data_parecer}</div>
                </div> */}
                <br />
                <p><b>1. Histórico do beneficiário(a):</b></p>
                <p>{data.desempatador_responsavel_historico_beneficiario ? data.desempatador_responsavel_historico_beneficiario : 'Não informado.'}</p>

                <p><b>2. Análise:</b></p>

                <div className="w-100">
                    <p className="text-center mt-30 mb-20 fb-16">ANÁLISE DO PROFISSIONAL ESPECIALISTA | PROCEDIMENTOS/EXAMES</p>
                </div>
                {data.procedimentos ?
                    <div className="w-100 grid">
                        <table>
                            <tbody style={{ width: '100%' }}>
                                <tr style={{ width: '100%' }}>
                                    <th style={{ width: '15%' }}>Código</th>
                                    <th style={{ width: '45%' }}>Descrição</th>
                                    <th style={{ width: '20%' }}>Quantidade Solicitada</th>
                                    <th style={{ width: '20%' }}>Quantidade Pós Análise</th>
                                </tr>
                            </tbody>
                            {data.procedimentos.map(procedimento => {
                                return <tbody key={procedimento.id}>
                                    <tr>
                                        <td>{procedimento.codigo}</td>
                                        <td>{procedimento.descricao}</td>
                                        <td>{procedimento.quantidade_solicitada}</td>
                                        <td>{procedimento.quantidade_pos_analise === 0 ? procedimento.quantidade_autorizada : procedimento.quantidade_pos_analise}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" className="text-justify"><b>Justificativa: </b>{procedimento.justificativa_pos_analise}</td>
                                    </tr>
                                </tbody>
                            })}
                        </table></div> : ''}
                <div className="w-100">
                    <p className="text-center mt-30 mb-20 fb-16">ANÁLISE DO PROFISSIONAL ESPECIALISTA | MATERIAIS/MEDICAMENTOS</p>
                </div>
                {data.materiais ?
                    <div className="w-100 grid">
                        <table>
                            <tbody style={{ width: '100%' }}>
                                <tr style={{ width: '100%' }}>
                                    <th style={{ width: '60%' }}>Descrição</th>
                                    <th style={{ width: '20%' }}>Quantidade Solicitada</th>
                                    <th style={{ width: '20%' }}>Quantidade Pós Análise</th>
                                </tr>
                            </tbody>
                            {data.materiais.map(material => {
                                return <tbody key={material.id}>
                                    <tr>
                                        <td>{material.descricao}</td>
                                        <td>{material.quantidade_solicitada}</td>
                                        <td>{material.quantidade_pos_analise === 0 ? material.quantidade_autorizada : material.quantidade_pos_analise}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" className="text-justify"><b>Justificativa: </b>{material.justificativa_pos_analise}</td>
                                    </tr>
                                </tbody>
                            })}
                        </table></div> : ''}
                <br />

                <p><b>3. Conclusão:</b></p>
                <p className="bold">"{data.desempatador_responsavel_laudo || 'Não há.'}"</p>
                <br />
                <div className="w-100 text-justify bold pb-50">
                    <p>Atenciosamente,</p>
                    <p>Dr. (a) {data.desempatador_responsavel_nome}, CRM (ou CRO): {data.desempatador_responsavel_crmcro} - {data.desempatador_responsavel_uf}.</p>
                    <p>{data.desempatador_responsavel_descricao}</p>
                </div>
                <br />
                <div className="fn-12 text-center p-10 acqua-mark" style={{ border: '1px solid black' }}>
                    <span style={{ opacity: '1', position: 'relative' }}>
                        Assinado eletronicamente por:<br />
                        {data.desempatador_responsavel_nome}<br />
                        CRM (ou CRO): {data.desempatador_responsavel_crmcro}<br />
                        Data: {data.data_parecer_min}
                    </span>
                </div>
            </div>}
        </div>
    );
}
